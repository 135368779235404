import request from "superagent";
import { authProvider } from "../../../authProvider";
import { baseURL } from "../../../services/constants";
import { getAccessToken } from "../../../services/commonFunctions";
import i18next from "i18next";

let accounts;

// fetching available Infrigments for a company
export const COMPANY_YEARLY_INFRIGMENTS_FETCHED = "COMPANY_YEARLY_INFRIGMENTS_FETCHED";
export const DRIVERS_YEARLY_INFRIGMENTS_FETCHED = "DRIVERS_YEARLY_INFRIGMENTS_FETCHED";
export const COMPANY_MONTHLY_INFRIGMENTS_FETCHED = "COMPANY_MONTHLY_INFRIGMENTS_FETCHED";
export const DRIVERS_MONTHLY_INFRIGMENTS_FETCHED = "DRIVERS_MONTHLY_INFRIGMENTS_FETCHED";
export const MONTHLY_INFRIGMENTS_FETCHED = "MONTHLY_INFRIGMENTS_FETCHED";
export const COMPANY_MONTHLY_INFRIGMENTS_WITH_NAMES_FETCHED = "COMPANY_MONTHLY_INFRIGMENTS_WITH_NAMES_FETCHED";
export const RESET_TO_INITIAL_STATE = "RESET_TO_INITIAL_STATE";
export const TOGGLE_TACHO_LOADING = "TOGGLE_TACHO_LOADING";

const toggleTachoLoading = () => ({
  type: TOGGLE_TACHO_LOADING,
})

const companyYearlyInfrigmentsFetched = (data) => ({
    type: COMPANY_YEARLY_INFRIGMENTS_FETCHED,
    payload: data,
});

const resetDataToInitialState = () => ({
    type: RESET_TO_INITIAL_STATE,
    payload: [],
});

const companyMonthlyInfrigmentsFetched = (data) => ({
    type: COMPANY_MONTHLY_INFRIGMENTS_FETCHED,
    payload: data,
});

const companyMonthlyInfrigmentsWithNamesFetched = (data) => ({
    type: COMPANY_MONTHLY_INFRIGMENTS_WITH_NAMES_FETCHED,
    payload: data,
});

const driversYearlyInfrigmentsFetched = (data) => ({
    type: DRIVERS_YEARLY_INFRIGMENTS_FETCHED,
    payload: data,
});

const driversMonthlyInfrigmentsFetched = (data) => ({
    type: DRIVERS_MONTHLY_INFRIGMENTS_FETCHED,
    payload: data,
});

const monthlyInfrigmentsFetched = (data) => ({
    type: MONTHLY_INFRIGMENTS_FETCHED,
    payload: data,
});

export const getCompanyTachoOverviewYearly =
    (companyId, year, companyGroupId) => async (dispatch, getState) => {
    const store = getState();
    let { auth } = store;
    let token = auth?.accessToken;
    // companyGroupId = '';
    dispatch(toggleTachoLoading());
    // Get accessToken
    if (auth != null) {
        if (auth.accessToken == null) {
            try {
                accounts = authProvider.getAllAccounts();
                token = await getAccessToken(accounts, authProvider);
                auth = getState().auth;
            } catch (e) {
                console.log(e);
            }
        }
    }
    dispatch(resetDataToInitialState());
    request
        .get(`${baseURL}/Portal/management/Tacho/GetCompanyTachoOverviewYearly?companyId=${companyId}&year=${year}&companyGroupId=${companyGroupId}`)
        .set("Authorization", `Bearer ${token}`)
        .retry(1, (err, res) => {
            if (res.unauthorized) {
                console.log(
                    `401 error, retry from last logged in API, err is ${err}`
                );
                dispatch({
                    type: "ACQUIRE_TOKEN_FAILURE",
                    payload: null,
                });
            }
        })
        .then((response) => {
            dispatch(companyYearlyInfrigmentsFetched({ companyId: companyId, features: response.body, year: year, companyGroupId: companyGroupId }));
            dispatch(toggleTachoLoading());  
        })
        .catch((e) => {
            console.log(e.response);
            dispatch(toggleTachoLoading());  
            alert(
                i18next.t("translation:ErrorMsg.22", {
                errorMsg: e.message,
                errorCode: e.response.statusCode,
                })
            );
        });
};

export const getCompanyTachoOverviewMonthly = (companyId, companyGroupId, startDate, endDate) => async (dispatch, getState) => {
    const store = getState();
    let { auth } = store;
    let token = auth?.accessToken;
    
    // Get accessToken 
    if (auth != null) {
        if (auth.accessToken == null) {
        try {
            accounts = authProvider.getAllAccounts();
            token = await getAccessToken(accounts, authProvider);
            auth = getState().auth;
        } catch (e) {
            console.log(e);
        }
        }
    }
    dispatch(toggleTachoLoading());   
    request
        .get(`${baseURL}/portal/management/Tacho/GetCompanyTachoOverviewMonthly?companyId=${companyId}&companyGroupId=${companyGroupId}&startDate=${startDate}&endDate=${endDate}`)
        .set("Authorization", `Bearer ${token}`)
        .retry(1, (err, res) => {
        if (res.unauthorized) {
            console.log(`401 error, retry from a drivers table API, err is ${err}`);
            dispatch({
            type: "ACQUIRE_TOKEN_FAILURE",
            payload: null,
            });
        }
        })
        .then((response) => {
            dispatch(companyMonthlyInfrigmentsFetched({ companyId: companyId, features: response.body, companyGroupId: companyGroupId }));
            dispatch(toggleTachoLoading());
        })
        .catch((e) => {
        console.log(e.response);
        dispatch(toggleTachoLoading());
        alert(
            i18next.t("translation:ErrorMsg.22", {
            errorMsg: e.message,
            errorCode: e.response.statusCode,
            })
        );
    });
};

export const getCompanyTachoOverviewMonthlyWithNames = (companyId, startDate, endDate, companyGroupId) => async (dispatch, getState) => {
    const store = getState();
    let { auth } = store;
    let token = auth?.accessToken;
    
    // Get accessToken
    if (auth != null) {
        if (auth.accessToken == null) {
        try {
            accounts = authProvider.getAllAccounts();
            token = await getAccessToken(accounts, authProvider);
            auth = getState().auth;
        } catch (e) {
            console.log(e);
        }
        }
    }    
    dispatch(toggleTachoLoading());
    request
        .get(`${baseURL}/portal/management/Tacho/GetCompanyTachoOverviewMonthly?companyId=${companyId}&start=${startDate}&end=${endDate}&companyGroupId=${companyGroupId}`)
        .set("Authorization", `Bearer ${token}`)
        .retry(1, (err, res) => {
        if (res.unauthorized) {
            console.log(`401 error, retry from a drivers table API, err is ${err}`);
            dispatch({
            type: "ACQUIRE_TOKEN_FAILURE",
            payload: null,
            });
        }
        })
        .then((response) => {
            dispatch(companyMonthlyInfrigmentsWithNamesFetched({ companyId: companyId, features: response.body}));
            dispatch(toggleTachoLoading());
        })
        .catch((e) => {
        console.log(e.response);
        dispatch(toggleTachoLoading());
        alert(
            i18next.t("translation:ErrorMsg.22", {
            errorMsg: e.message,
            errorCode: e.response.statusCode,
            })
        );
    });
};

export const getDriverTachoOverviewYearly = (driverId, year) => async (dispatch, getState) => {
    const store = getState();
    let { auth } = store;
    let token = auth?.accessToken;
    // Get accessToken
    if (auth != null) {
        if (auth.accessToken == null) {
        try {
            accounts = authProvider.getAllAccounts();
            token = await getAccessToken(accounts, authProvider);
            auth = getState().auth;
        } catch (e) {
            console.log(e);
        }
        }
    }
        
    request
        .get(`${baseURL}/portal/management/Tacho/GetDriverTachoOverviewYearly?driverId=${driverId}&year=${year}`)
        .set("Authorization", `Bearer ${token}`)
        .retry(1, (err, res) => {
        if (res.unauthorized) {
            console.log(`401 error, retry from a drivers table API, err is ${err}`);
            dispatch({
            type: "ACQUIRE_TOKEN_FAILURE",
            payload: null,
            });
        }
        })
        .then((response) => {
            dispatch(driversYearlyInfrigmentsFetched({ driverId: driverId, features: response.body}));
        })
        .catch((e) => {
        console.log(e.response);
        // alert(
        //     i18next.t("translation:ErrorMsg.22", {
        //     errorMsg: e.message,
        //     errorCode: e.response.statusCode,
        //     })
        // );
    });
};

export const GetDriverTachoOverviewMonthly = (driverId, startDate, endDate) => async (dispatch, getState) => {
    const store = getState();
    let { auth } = store;
    let token = auth?.accessToken;
    
    // Get accessToken
    if (auth != null) {
        if (auth.accessToken == null) {
            try {
                accounts = authProvider.getAllAccounts();
                token = await getAccessToken(accounts, authProvider);
                auth = getState().auth;
            } catch (e) {
                console.log(e);
            }
        }
    }
        
    request
        .get(`${baseURL}/portal/management/Tacho/GetDriverTachoOverviewMonthly?driverId=${driverId}&startDate=${startDate}&endDate=${endDate}`)
        .set("Authorization", `Bearer ${token}`)
        .retry(1, (err, res) => {
        if (res.unauthorized) {
            console.log(`401 error, retry from a drivers table API, err is ${err}`);
            dispatch({
            type: "ACQUIRE_TOKEN_FAILURE",
            payload: null,
            });
        }
        })
        .then((response) => {
            dispatch(driversMonthlyInfrigmentsFetched({ driverId: driverId, features: response.body}));
        })
        .catch((e) => {
        console.log(e.response);
        alert(
            i18next.t("translation:ErrorMsg.22", {
            errorMsg: e.message,
            errorCode: e.response.statusCode,
            })
        );
    });
};

export const getInfringementsPerMonth = (driverId, startDate, endDate) => async (dispatch, getState) => {
    const store = getState();
    let { auth } = store;
    let token = auth?.accessToken;
    // driverId='74ec96f7-dd01-441b-9?8a6-1f727c18a85c'; 
    
    // Get accessToken
    if (auth != null) {
        if (auth.accessToken == null) {
            try {
                accounts = authProvider.getAllAccounts();
                token = await getAccessToken(accounts, authProvider);
                auth = getState().auth;
            } catch (e) {
                console.log(e);
            }
        }
    }
        
    request
        .get(`${baseURL}/portal/management/Tacho/GetInfringementsPerMonth?driverId=${driverId}&start=${startDate}&end=${endDate}`)
        .set("Authorization", `Bearer ${token}`)
        .retry(1, (err, res) => {
        if (res.unauthorized) {
            console.log(`401 error, retry from a drivers table API, err is ${err}`);
            dispatch({
            type: "ACQUIRE_TOKEN_FAILURE",
            payload: null,
            });
        }
        })
        .then((response) => {
            dispatch(monthlyInfrigmentsFetched({ driverId: driverId, features: response.body}));
        })
        .catch((e) => {
        console.log(e.response);
        alert(
            i18next.t("translation:ErrorMsg.22", {
            errorMsg: e.message,
            errorCode: e.response.statusCode,
            })
        );
    });
};