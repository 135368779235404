import request from "superagent";
//import { baseURL } from "../../../services/constants";
import { outlierScoresReset } from "./outlierScores";
import { monthlyScoresReset, scoresReset } from "./scores";
import { driverStatsReset, driverStatsHistoryReset, driverWeeklyStatsReset, driverMonthlyStatsReset } from "./driverStats";
import { lastLoggedInDateReset } from "./loggedInDate";
import { driverRankReset } from "./driverRank";
import { messagesReset } from "./messages";
import { dailyScoresReset } from "./scores";
import { driverDailyStatsReset } from "./driverStats";
import { baseURL } from "../../../services/constants";
import { weeklyScoreHistoryReset } from "./scoreHistory";
import { resetCoachMsgWhiledriverChange } from "./companies";
import { clearAiTextLoaded } from "./coachAssist";

// fetching a single driver
export const DRIVER_FETCHED = "DRIVER_FETCHED";

const driverFetched = (driver) => ({
  type: DRIVER_FETCHED,
  payload: driver,
});

//Add postponed status to driver
export const DRIVER_POSTPONED_STATUS_ADDED = "DRIVER_POSTPONED_STATUS_ADDED";

export const postponedStatusAddedToDriver = () => ({
  type: DRIVER_POSTPONED_STATUS_ADDED,
});

// Remove postponed status from driver
export const DRIVER_POSTPONED_STATUS_REMOVED =
  "DRIVER_POSTPONED_STATUS_REMOVED";

export const postponedStatusRemovedFromDriver = () => ({
  type: DRIVER_POSTPONED_STATUS_REMOVED,
});

//Add unread status to driver
export const DRIVER_NEWDRIVER_STATUS_ADDED = "DRIVER_NEWDRIVER_STATUS_ADDED";

export const newDriverStatusAddedToDriver = () => ({
  type: DRIVER_NEWDRIVER_STATUS_ADDED,
});

//Add openIssue status to driver
export const DRIVER_OPENISSUE_STATUS_ADDED = "DRIVER_OPENISSUE_STATUS_ADDED";

export const openIssueStatusAddedToDriver = () => ({
  type: DRIVER_OPENISSUE_STATUS_ADDED,
});

//Clear openIssue status from driver
export const DRIVER_OPENISSUE_STATUS_REMOVED = "DRIVER_OPENISSUE_STATUS_REMOVED";

export const openIssueStatusRemovedFromDriver = () => ({
  type: DRIVER_OPENISSUE_STATUS_REMOVED,
});

// Remove unread status from driver
export const DRIVER_UNREAD_STATUS_REMOVED = "DRIVER_UNREAD_STATUS_REMOVED";

export const unreadStatusRemovedFromDriver = () => ({
  type: DRIVER_UNREAD_STATUS_REMOVED,
});

//Add newDriver status to driver
export const DRIVER_UNREAD_STATUS_ADDED = "DRIVER_UNREAD_STATUS_ADDED";

export const unreadStatusAddedToDriver = () => ({
  type: DRIVER_UNREAD_STATUS_ADDED,
});

// Remove newDriver status from driver
export const DRIVER_NEWDRIVER_STATUS_REMOVED = "DRIVER_NEWDRIVER_STATUS_REMOVED";

export const newDriverStatusRemovedFromDriver = () => ({
  type: DRIVER_NEWDRIVER_STATUS_REMOVED,
});

// Remove overDue status from driver
export const DRIVER_OVERDUE_STATUS_REMOVED = "DRIVER_OVERDUE_STATUS_REMOVED";

export const overDueStatusRemovedFromDriver = () => ({
  type: DRIVER_OVERDUE_STATUS_REMOVED,
});

// resetting the driver already loaded
export const DRIVER_RESETTED = "DRIVER_RESETTED";

const driverReset = () => {
  return { type: DRIVER_RESETTED };
};

// setting the loader to true
export const DRIVER_LOADING_TRUE = "DRIVER_LOADING_TRUE";

const setDriverIsLoading = () => ({
  type: DRIVER_LOADING_TRUE,
});

// setting the loader to true
export const DRIVER_LOADING_FALSE = "DRIVER_LOADING_FALSE";

const setDriverIsNotLoading = () => ({
  type: DRIVER_LOADING_FALSE,
});

export const loadDriver = (driverId) => async (dispatch, getState) => {
  const store = getState();
  const { auth } = store;
  const { driver } = store;
  const { driverLoading } = store;
  let token = auth?.accessToken;

  // Check if a driver is already loaded
  if (driver) {
    // If it's the same driver which wants to reload, don't send the API request
    if (driver.driverId === driverId) {
      return;
    } else {
      // Delete driver if another driver is going to be loaded
      dispatch(driverReset());
      // reset the outlier scores if the scores loaded are from a different driver
      dispatch(outlierScoresReset());
      // reset the scores if the scores loaded are from a different driver
      dispatch(scoresReset());
      // reset driver stats if another driver data is going to be loaded
      dispatch(driverStatsReset());
      dispatch(driverStatsHistoryReset());
      dispatch(lastLoggedInDateReset());
      dispatch(driverRankReset());
      dispatch(messagesReset());
      dispatch(resetCoachMsgWhiledriverChange());

      //TODO: These needs to be sent only if feature is enabled for this driver
      dispatch(dailyScoresReset());
      dispatch(driverDailyStatsReset());
      dispatch(driverWeeklyStatsReset());
      dispatch(weeklyScoreHistoryReset());
      dispatch(monthlyScoresReset());
      dispatch(driverMonthlyStatsReset());
      dispatch(clearAiTextLoaded());
    }
  }

  if (!driverLoading) {
    dispatch(setDriverIsLoading());
    request
      .get(
        `${baseURL}/portal/coaching/Drivers/GetDriver?id=${driverId}&includePersonalDetails=true&includeCompany=true&includeStatuses=true`
      )
      .set("Authorization", `Bearer ${token}`)
      .retry(1, (err, res) => {
        if (res.unauthorized) {
          console.log(`401 error, retry API, err is ${err}`);
          dispatch({
            type: "ACQUIRE_TOKEN_FAILURE",
            payload: null,
          });
        }
      })
      .then((response) => {
        dispatch(driverFetched(response.body));
        dispatch(setDriverIsNotLoading());
      })
      .catch((e) => {
        console.error(e), dispatch(setDriverIsNotLoading());
      });
  }
};
