import i18n from "../../../i18n";

export const nameTranslation = (scoreName) => {
  switch (scoreName) {
    case "Uitrollen":
      return {
        translation: i18n.t("scores:Coasting.1"),
      };
    case "Cruise control":
      return {
        translation: i18n.t("scores:CruiseControl.1"),
      };
    case "Remmen":
      return {
        translation: i18n.t("scores:Braking.1"),
      };
    case "Weekscore":
      return {
        translation: i18n.t("scores:WeekScore.1"),
      };
    case "Stationair draaien (ex. PTO)":
      return {
        translation: i18n.t("scores:Idling.1"),
      };
    case "Afremmen":
      return {
        translation: i18n.t("scores:Deceleration.1"),
      };
    case "Snelheid":
      return {
        translation: i18n.t("scores:Speeding.1"),
      };
    case "Panic Braking":
      return {
        translation: i18n.t("scores:PanicBraking.1"),
      };
    case "High RPM":
      return {
        translation: "RPM",
      };
    case "Optrekken":
      return {
        translation: i18n.t("scores:Acceleration.1"),
      };
    default:
      return {
        translation: scoreName,
      };
  }
};

// translates the language to language code (e.g. "en") for the API call
export const translateToCode = (language) => {
  let langSelection;
  switch (language) {
    case i18n.t("driversTable:Languages.1"):
      langSelection = "nl-NL";
      break;
    case i18n.t("driversTable:Languages.2"):
      langSelection = "en";
      break;
    case i18n.t("driversTable:Languages.3"):
      langSelection = "de";
      break;
    case i18n.t("driversTable:Languages.4"):
      langSelection = "fr";
      break;
    case i18n.t("driversTable:Languages.5"):
      langSelection = "ro";
      break;
    case i18n.t("driversTable:Languages.6"):
      langSelection = "es";
      break;
    case i18n.t("driversTable:Languages.7"):
      langSelection = "pl";
      break;
    case i18n.t("driversTable:Languages.8"):
      langSelection = "da";
      break;
    default:
      langSelection = "nl-NL";
      break;
  }
  return langSelection;
};

// translates the language code (e.g. "en") to the appropriate language
export const translateToLang = (langCode) => {
  let langSelection;
  switch (langCode) {
    case "nl-NL":
      langSelection = i18n.t("driversTable:Languages.1");
      break;
    case "en":
      langSelection = i18n.t("driversTable:Languages.2");
      break;
    case "de":
      langSelection = i18n.t("driversTable:Languages.3");
      break;
    case "fr":
      langSelection = i18n.t("driversTable:Languages.4");
      break;
    case "ro":
      langSelection = i18n.t("driversTable:Languages.5");
      break;
    case "es":
      langSelection = i18n.t("driversTable:Languages.6");
      break;
    case "pl":
      langSelection = i18n.t("driversTable:Languages.7");
      break;
    case "da":
      langSelection = i18n.t("driversTable:Languages.8");
      break;
    default: // this means "unknown". or return Dutch?
      langSelection = i18n.t("driversTable:Languages.1");
      break;
  }
  return langSelection;
};

// M portal drivers table: importing drivers data from CSV -> accepted language input options translation to code that the backend expects
export const languageParserFromCsv = (langInput = "nl") => {
  // default value is "nl" if no value was provided
  if (!langInput) langInput = "nl"; // if value is undefined then the default is "nl"

  //["language code", "lang-country code", "english translation", "dutch translation", "german translation", "french translation", "romanian translation"]
  const languageOptions = [
    [
      "nl",
      "nl-nl",
      "dutch",
      "nederlands",
      "niederländisch",
      "néerlandais",
      "olandeză",
    ], // Dutch input options accepted from CSV
    ["en", "en-uk", "english", "engels", "englisch", "anglais", "engleză"], // English
    ["de", "ge-de", "German", "duits", "deutsch", "allemand", "germană"], // German
    ["fr", "fr-fr", "french", "frans", "französisch", "français", "franceză"], // French
    ["ro", "ro-ro", "romanian", "roemeens", "rumänisch", "roumain", "românesc"],
  ];

  const lowerCaseLangInput = langInput.toLowerCase(); // input is case insensitive
  const indexOfLanguageGroup = languageOptions.findIndex((langGroup) =>
    langGroup.includes(lowerCaseLangInput)
  );

  switch (indexOfLanguageGroup) {
    case 0:
      return "nl-NL";
    case 1:
      return "en";
    case 2:
      return "de";
    case 3:
      return "fr";
    case 4:
      return "ro-RO";
    default:
      return "nl-NL";
  }
};

// M portal drivers table: importing drivers data from CSV -> accepted phone num input options parsing to a format that the backend expects (31622446688 instead of 06... or +316...)
export const phoneNumParserFromCsv = (phoneNumInput) => {
  if (phoneNumInput === "") return ""; // in case of empty input
  //Filter only numbers from the input
  let cleaned = ("" + phoneNumInput).replace(/\D/g, "");

  let result = cleaned;

  const firstTwoChars = cleaned.slice(0, 2);
  // checking if it starts w 06...
  if (firstTwoChars === "06") {
    result = cleaned.replace(/06/, "316");
  }
  return result;
};

export const getZPercent = (z) => {
  //z == number of standard deviations from the mean

  //if z is greater than 6.5 standard deviations from the mean
  //the number of significant digits will be outside of a reasonable range
  if (z < -6.5) return 0.0;
  if (z > 6.5) return 1.0;

  let factK = 1;
  let sum = 0;
  let term = 1;
  let k = 0;
  let loopStop = Math.exp(-23);
  while (Math.abs(term) > loopStop) {
    term =
      (((0.3989422804 * Math.pow(-1, k) * Math.pow(z, k)) /
        (2 * k + 1) /
        Math.pow(2, k)) *
        Math.pow(z, k + 1)) /
      factK;
    sum += term;
    k++;
    factK *= k;
  }
  sum += 0.5;

  return sum;
};

// calculates the week index for a given date
export const getWeek = (date) => {
  date.setHours(0, 0, 0, 0);
  // Thursday in current week decides the year.
  date.setDate(date.getDate() + 3 - ((date.getDay() + 6) % 7));
  // January 4 is always in week 1.
  let weekOne = new Date(date.getFullYear(), 0, 4);
  // Adjust to Thursday in week 1 and count number of weeks from date to weekOne.
  return (
    1 +
    Math.round(
      ((date.getTime() - weekOne.getTime()) / 86400000 -
        3 +
        ((weekOne.getDay() + 6) % 7)) /
      7
    )
  );
};

// given a date, the function calculates the latest Monday date for sending an API request
export const dateParser = (date) => {
  let latestMondayDate;
  if (typeof date === "string") {
    // initial load, so the date is today
    const today = new Date();
    const dayOfWeek = today.getDay();
    latestMondayDate = new Date();
    if (dayOfWeek >= 1) {
      // if it's either Monday or later that week
      const differenceToMonday = dayOfWeek - 1;
      latestMondayDate.setDate(today.getDate() - differenceToMonday);
    } else {
      // if it's Sunday, the last Monday was 6 days ago
      latestMondayDate.setDate(today.getDate() - 6);
    }
  } else {
    const dayOfWeek = date.getDay();
    latestMondayDate = date;
    if (dayOfWeek >= 1) {
      // if it's either Monday or later that week
      const differenceToMonday = dayOfWeek - 1;
      latestMondayDate.setDate(date.getDate() - differenceToMonday);
    } else {
      // if it's Sunday, the last Monday was 6 days ago
      latestMondayDate.setDate(date.getDate() - 6);
    }
  }
  return {
    year: latestMondayDate.getFullYear(),
    month: latestMondayDate.getMonth() + 1,
    day: latestMondayDate.getDate(),
  };
};

// calculates the given date's week's Monday's date (so the latest Monday's date)
export const calcStartDate = (date) => {
  const dayOfWeek = date.getDay();
  let latestMondayDate = date;
  if (dayOfWeek >= 1) {
    // if it's either Monday or later that week
    const differenceToMonday = dayOfWeek - 1;
    latestMondayDate.setDate(date.getDate() - differenceToMonday - 7); // - 7 because the API returns data from the prev week
  } else {
    // if it's Sunday, the last Monday was 6 days ago (- 7 because the API returns data from the prev week)
    latestMondayDate.setDate(date.getDate() - 6 - 7);
  }
  return latestMondayDate;
};

// calculates the given date's latest Sunday's date
export const calcEndDate = (date) => {
  const monday = calcStartDate(date);
  let latestSundayDate = date;
  latestSundayDate.setDate(monday.getDate() + 6);
  return latestSundayDate;
};

//Filter function to filter out driver list with given lable{unread/ postponed/ overdue}
export const isDriverWithGivenLabel = (driver, labels) => {
  let countOfLabels = labels.length;
  let count = 0;
  labels.forEach((element) => {
    Object.entries(driver.info).map(([key, value]) => {
      if (element.toLowerCase() === key.toLowerCase() && value) {
        count++;
      }
    });
  });
  //Return true for only those drivers who have all selected labels applied
  return count === countOfLabels;
};


export const getweekIndecesArray = (object) => {
  const startWeek = object.startWeek;
  const endWeek = object.endWeek;
  const array = [];
  for (let i = startWeek; i <= endWeek; i++) {
    array.push(i);
  }
  return array;
}

export const getLastWeekMonday = () => {
  const today = new Date();
  const lastMonday = new Date(today);

  // Find the day of the week (0 = Sunday, 1 = Monday, ... 6 = Saturday)
  const currentDayOfWeek = today.getDay();

  // Calculate the number of days to subtract to get to last Monday
  const daysToSubtract = (currentDayOfWeek + 6) % 7;

  lastMonday.setDate(today.getDate() - daysToSubtract);
  return lastMonday;
}

export const getPreviousMondays = () => {
  const previousMondays = [];

  const currentDate = new Date();

  // Calculate the date 5 weeks ago
  const fiveWeeksAgo = new Date(currentDate);
  fiveWeeksAgo.setDate(currentDate.getDate() - (7 * 5));

  // Find the most recent Monday before or on the 6 weeks ago date
  while (fiveWeeksAgo.getDay() !== 1) {
    fiveWeeksAgo.setDate(fiveWeeksAgo.getDate() - 1);
  }

  // Calculate the date of the previous week's Monday
  const previousWeekMonday = new Date(currentDate);
  previousWeekMonday.setDate(currentDate.getDate() - (currentDate.getDay() + 6) % 7 - 7);

  // Add the dates to the array
  for (let i = 0; i < 5; i++) {
    previousMondays.push(new Date(fiveWeeksAgo));
    fiveWeeksAgo.setDate(fiveWeeksAgo.getDate() + 7);
  }

  return previousMondays; // Reverse the array to have the latest date first
}