import request from "superagent";
import { authProvider } from "../../../../authProvider";
import { baseURL } from "../../../../services/constants";
import { dateParser } from "../../../Coaching/Utils/functions";
import { getAccessToken } from "../../../../services/commonFunctions";

let accounts;

// fetching a driver's statistics (distance driven in km, percentage of long distance journeys)
export const DRIVER_STATS_FETCHED = "DRIVER_STATS_FETCHED";

const driverStatsFetched = (stats) => ({
  type: DRIVER_STATS_FETCHED,
  payload: stats,
});

// resetting the driver's statistics already loaded
export const DRIVER_STATS_RESETTED = "DRIVER_STATS_RESETTED";

export const driverStatsReset = () => ({
  type: DRIVER_STATS_RESETTED,
});

export const loadDriverStats = (driverId, date) => async (
  dispatch,
  getState
) => {
  const store = getState();
  let { auth } = store;
  const { driverInManagement } = store;
  const { driverStats } = store;
  let dateObject = dateParser(date);
  let token = auth?.accessToken;

  if (date === "initialLoad") {
    dateObject = dateParser(new Date());
    // Check if scores and a driver is already loaded
    if (driverInManagement && driverStats) {
      // Check if the request is for the same driver
      if (driverInManagement.driverId === driverId) {
        // Don't send the request if the same driver is already loaded with no stats from before
        return;
      }
    }
  }

  // Get accessToken
  if (auth != null) {
    if (auth.accessToken == null) {
      try {
        accounts = authProvider.getAllAccounts();
        token = await getAccessToken(accounts, authProvider);
        auth = getState().auth;
      } catch (e) {
        console.log(e);
      }
    }
  }

  request
    .get(
      `${baseURL}/Portal/Management/Statistics/GetDriverCoachingStatistics?DriverId=${driverId}&year=${dateObject.year}&month=${dateObject.month}&day=${dateObject.day}`
    )
    .set("Authorization", `Bearer ${token}`)
    .retry(1, (err, res) => {
      if (res.unauthorized) {
        console.log(`401 error, retry from driver stats APIs, err is ${err}`);
        dispatch({
          type: "ACQUIRE_TOKEN_FAILURE",
          payload: null,
        });
      }
    })
    .then((response) => {
      dispatch(driverStatsFetched(response.body));
      if (date === "initialLoad") {
        dispatch(driverStatsDateResetted()); // reset the stats history date range if we switched to a new driver
      }
    })
    .catch(console.error);
};

// changing the driver's statistics' date range
export const DRIVER_STATS_DATE_CHANGED = "DRIVER_STATS_DATE_CHANGED";

export const driverStatsDateChange = (dateObject) => ({
  type: DRIVER_STATS_DATE_CHANGED,
  payload: dateObject,
});

// resetting the driver's statistics' date range to the latest available date
export const DRIVER_STATS_DATE_RESETTED = "DRIVER_STATS_DATE_RESETTED";

export const driverStatsDateResetted = () => ({
  type: DRIVER_STATS_DATE_RESETTED,
});

// resetting the driver's statistics history already loaded
export const DRIVER_STATS_HISTORY_RESETTED = "DRIVER_STATS_HISTORY_RESETTED";

export const driverStatsHistoryReset = () => ({
  type: DRIVER_STATS_HISTORY_RESETTED,
});

// fetching a driver's statistics history (distance driven in km, percentage of long distance journeys)
export const DRIVER_STATS_HISTORY_FETCHED = "DRIVER_STATS_HISTORY_FETCHED";

const driverStatsHistoryFetched = (stats) => ({
  type: DRIVER_STATS_HISTORY_FETCHED,
  payload: stats,
});

export const loadDriverStatsHistory = (driverId, date) => async (
  dispatch,
  getState
) => {
  const store = getState();
  let { auth } = store;
  const { driverInManagement } = store;
  const { driverStats } = store;
  let dateObject = dateParser(date);
  let token = auth?.accessToken;

  if (date === "initialLoad") {
    dateObject = dateParser(new Date());
    // Check if scores and a driver is already loaded
    if (driverInManagement && driverStats) {
      // Check if the request is for the same driver
      if (driverInManagement.driverId === driverId) {
        // Don't send the request if the same driver is already loaded with no stats from before
        return;
      }
    }
  }

  // Get accessToken
  if (auth != null) {
    if (auth.accessToken == null) {
      try {
        accounts = authProvider.getAllAccounts();
        token = await getAccessToken(accounts, authProvider);
        auth = getState().auth;
      } catch (e) {
        console.log(e);
      }
    }
  }

  request
    .get(
      `${baseURL}/Portal/Management/Statistics/GetDriverCoachingStatistics?DriverId=${driverId}&year=${dateObject.year}&month=${dateObject.month}&day=${dateObject.day}`
    )
    .set("Authorization", `Bearer ${token}`)
    .retry(1, (err, res) => {
      if (res.unauthorized) {
        console.log(`401 error, retry from driver stats APIs, err is ${err}`);
        dispatch({
          type: "ACQUIRE_TOKEN_FAILURE",
          payload: null,
        });
      }
    })
    .then((response) => {
      dispatch(driverStatsHistoryFetched(response.body));
      if (date === "initialLoad") {
        dispatch(driverStatsDateResetted()); // reset the stats history date range if we switched to a new driver
      }
    })
    .catch(console.error);
};

//fetching driver daily stats
export const DRIVER_DAILY_STATS_FETCHED = "DRIVER_DAILY_STATS_FETCHED";

const driverDailyStatsFetched = (stats) => ({
  type: DRIVER_DAILY_STATS_FETCHED,
  payload: stats,
});

// resetting the driver's daily statistics already loaded
export const DRIVER_DAILY_STATS_RESETTED = "DRIVER_DAILY_STATS_RESETTED";

export const driverDailyStatsReset = () => ({
  type: DRIVER_DAILY_STATS_RESETTED,
});


//daily stats API initial load
export const loadDailyDriverStats = (driverId, date, direction, companyId) => async (
  dispatch,
  getState
) => {
  const store = getState();
  let { auth } = store;
  const { driver } = store;
  const { dailyStats, coachingCompanyFeatures } = store;
  let token = auth?.accessToken;
  let dateObject = getYearMonthDateObject(date, direction);

  if (date === "initialLoad") {
    if (coachingCompanyFeatures) {
      const findCompany = coachingCompanyFeatures.find((company) => companyId?.toLowerCase()?.includes(company?.companyName.toLowerCase()))
      if (findCompany) {
        const findFeature = findCompany?.features?.find((feature) => feature.name === "daily_scores")
        if (!findFeature) {
          return;
        }
      }
    }

    // Check if stats and a driver is already loaded
    if (driver && dailyStats) {
      // Check if the request is for the same driver
      if (driver.driverId === driverId) {
        // Don't send the request if the same driver is already loaded with no stats from before
        return;
      }
    }
  } else {
    dispatch(driverDailyStatsReset())
  }

  // Get accessToken
  if (auth != null) {
    if (auth.accessToken == null) {
      try {
        accounts = authProvider.getAllAccounts();
        token = await getAccessToken(accounts, authProvider);
        auth = getState().auth;
      } catch (e) {
        console.log(e);
      }
    }
  }

  request
    .get(
      `${baseURL}/Portal/Management/Statistics/GetDriverDailyCoachingStatistics?DriverId=${driverId}&daysSpan=14&end=${dateObject.year}-${dateObject.month}-${dateObject.day}`
    )
    .set("Authorization", `Bearer ${token}`)
    .retry(1, (err, res) => {
      if (res.unauthorized) {
        console.log(`401 error, retry from driver stats APIs, err is ${err}`);
        dispatch({
          type: "ACQUIRE_TOKEN_FAILURE",
          payload: null,
        });
      }
    })
    .then((response) => {
      dispatch(driverDailyStatsFetched(response.body));  
    })
    .catch(console.error);
};


//fetching driver weekly stats
export const DRIVER_WEEKLY_STATS_FETCHED = "DRIVER_WEEKLY_STATS_FETCHED";

const driverWeeklyStatsFetched = (stats) => ({
  type: DRIVER_WEEKLY_STATS_FETCHED,
  payload: stats,
});

// resetting the driver's weekly statistics already loaded
export const DRIVER_WEEKLY_STATS_RESETTED = "DRIVER_WEEKLY_STATS_RESETTED";

export const driverWeeklyStatsReset = () => ({
  type: DRIVER_WEEKLY_STATS_RESETTED,
});
export const loadWeeklyDriverStats = (driverId, date,weekSpan, companyId) => async (
  dispatch,
  getState
) => {
  const store = getState();
  let { auth } = store;
  const { driver } = store;
  const { weeklyStats, coachingCompanyFeatures } = store;
  let token = auth?.accessToken;
  let dateObject = getYearMonthDateObjectForWeekly(date);



  if (date === "initialLoad") {
    if (coachingCompanyFeatures) {
      const findCompany = coachingCompanyFeatures.find((company) => companyId?.toLowerCase()?.includes(company?.companyName.toLowerCase()))
      if (findCompany) {
        const findFeature = findCompany?.features?.find((feature) => feature.name === "daily_scores")
        if (!findFeature) {
          return;
        }
      }
    }
    
    weekSpan=2;
    // Check if scores and a driver is already loaded
    if (driver && weeklyStats) {
      // Check if the request is for the same driver
      if (driver.driverId === driverId) {
        // Don't send the request if the same driver is already loaded with no stats from before
        return;
      }
    }
  } else {
    dispatch(driverWeeklyStatsReset())
  }

  // Get accessToken
  if (auth != null) {
    if (auth.accessToken == null) {
      try {
        accounts = authProvider.getAllAccounts();
        token = await getAccessToken(accounts, authProvider);
        auth = getState().auth;
      } catch (e) {
        console.log(e);
      }
    }
  }

  request
    .get(
      `${baseURL}/Portal/Management/Statistics/GetWeeklyStatsPerStatisticType?DriverId=${driverId}&end=${dateObject.year}-${dateObject.month}-${dateObject.day}&weeksSpan=${weekSpan}`
    )
    .set("Authorization", `Bearer ${token}`)
    .retry(1, (err, res) => {
      if (res.unauthorized) {
        console.log(`401 error, retry from driver stats APIs, err is ${err}`);
        dispatch({
          type: "ACQUIRE_TOKEN_FAILURE",
          payload: null,
        });
      }
    })
    .then((response) => {
      dispatch(driverWeeklyStatsFetched(response.body));
    })
    .catch(console.error);
};

//fetching driver monthly stats
export const DRIVER_MONTHLY_STATS_FETCHED = "DRIVER_MONTHLY_STATS_FETCHED";

const driverMonthlyStatsFetched = (stats) => ({
  type: DRIVER_MONTHLY_STATS_FETCHED,
  payload: stats,
});

// resetting the driver's monthly statistics already loaded
export const DRIVER_MONTHLY_STATS_RESETTED = "DRIVER_MONTHLY_STATS_RESETTED";

export const driverMonthlyStatsReset = () => ({
  type: DRIVER_MONTHLY_STATS_RESETTED,
});
export const loadMonthlyDriverStats = (driverId, date, companyId) => async (
  dispatch,
  getState
) => {
  const store = getState();
  let { auth } = store;
  const { driver } = store;
  const { monthlyStats, coachingCompanyFeatures } = store;
  let token = auth?.accessToken;
  let dateObject = new Date(date);



  if (date === "initialLoad") {
    if (coachingCompanyFeatures) {
      const findCompany = coachingCompanyFeatures.find((company) => companyId?.toLowerCase()?.includes(company?.companyName.toLowerCase()))
      if (findCompany) {
        const findFeature = findCompany?.features?.find((feature) => feature.name === "daily_scores")
        if (!findFeature) {
          return;
        }
      }
    }
    dateObject=new Date()
    // Check if scores and a driver is already loaded
    if (driver && monthlyStats) {
      // Check if the request is for the same driver
      if (driver.driverId === driverId) {
        // Don't send the request if the same driver is already loaded with no stats from before
        return;
      }
    }
  } else {
    dispatch(driverMonthlyStatsReset())
  }

  // Get accessToken
  if (auth != null) {
    if (auth.accessToken == null) {
      try {
        accounts = authProvider.getAllAccounts();
        token = await getAccessToken(accounts, authProvider);
        auth = getState().auth;
      } catch (e) {
        console.log(e);
      }
    }
  }

  request
    .get(
      `${baseURL}/Portal/Management/Statistics/GetMonthlyStatsPerStatisticType?DriverId=${driverId}&monthsSpan=5&end=${dateObject?.getFullYear()}-${dateObject?.getMonth()+1}-${dateObject?.getDate()}`
    )
    .set("Authorization", `Bearer ${token}`)
    .retry(1, (err, res) => {
      if (res.unauthorized) {
        console.log(`401 error, retry from driver stats APIs, err is ${err}`);
        dispatch({
          type: "ACQUIRE_TOKEN_FAILURE",
          payload: null,
        });
      }
    })
    .then((response) => {
      dispatch(driverMonthlyStatsFetched(response.body));
    })
    .catch(console.error);
};

const getYearMonthDateObject = (date, direction) => {
  let todaysDate;
  let nextWeeksDate;
  if (date === "initialLoad") {
    todaysDate = new Date();
    // if want to fetch data from yesterday try this
    // todaysDate.setDate(todaysDate.getDate() - 1);
  } else if (direction === "next") {
    todaysDate = new Date(date);
    todaysDate.setDate(todaysDate.getDate() + 14)
    nextWeeksDate = new Date(todaysDate);

    return {
      year: nextWeeksDate.getFullYear(),
      month: nextWeeksDate.getMonth() + 1,
      day: nextWeeksDate.getDate(),
    }
    // console.log("todays date", todaysDate)


  }
  else {
    todaysDate = new Date(date);
    todaysDate.setDate(todaysDate.getDate() - 1)
    // console.log("todays date", todaysDate)


  }

  return {
    year: todaysDate.getFullYear(),
    month: todaysDate.getMonth() + 1,
    day: todaysDate.getDate(),
  }
}

const getYearMonthDateObjectForWeekly = (weekObject) => {
  let todaysDate;
  if (weekObject === "initialLoad") {
    todaysDate = new Date();
  }
  else {
    // const date = new Date();

    // const getDateFromObject = getStartOfWeek(date.getFullYear(), weekObject?.endWeek + 1)
    todaysDate = new Date(weekObject)
    const dateObject = new Date(weekObject)
    todaysDate.setDate(dateObject.getDate() + 7)
  }

  return {
    year: todaysDate.getFullYear(),
    month: todaysDate.getMonth() + 1,
    day: todaysDate.getDate(),
  }
}