import request from "superagent";
import { authProvider } from "../../../../authProvider";
import { baseURL } from "../../../../services/constants";
import { getAccessToken } from "../../../../services/commonFunctions";

let accounts;

// fetching a single driver's scores of the latest week
export const SCORES_FETCHED = "SCORES_FETCHED";

const scoresFetched = (scores) => ({
  type: SCORES_FETCHED,
  payload: scores,
});

// resetting the score already loaded
export const SCORES_RESETTED = "SCORES_RESETTED";

export const scoresReset = () => ({
  type: SCORES_RESETTED,
});

export const loadScores = (driverId) => async (dispatch, getState) => {
  const store = getState();
  var { auth } = store;
  const { driverInManagement } = store;
  const { undoableScores } = store;
  let token = auth?.accessToken;

  // Check if scores and a driver is already loaded
  if (driverInManagement && undoableScores.present) {
    // Check if the request is for the same driver
    if (driverInManagement.driverId === driverId && undoableScores.present.length > 0) {
      const today = new Date();
      const thisWeekIndex = getWeek(today);
      const historyWeekIndex = getWeek(
        new Date(undoableScores.present[0].start)
      );
      // If scores are from previous week (correct), don't send the request again
      if (historyWeekIndex === thisWeekIndex - 1) {
        return;
      } else {
        // Delete scores if the present scores are not from previous week & continue with the request
        dispatch(scoresReset());
      }
    } else {
      // Don't send the request if the same driver is already loaded with no scores from before
      return;
    }
  }

  // Get accessToken
  if (auth != null) {
    if (auth.accessToken == null) {
      try {
        accounts = authProvider.getAllAccounts();
        token = await getAccessToken(accounts, authProvider);
        auth = getState().auth;
      } catch (e) {
        console.log(e);
      }
    }
  }

  if (auth.accessToken) {
    request
      .get(
        `${baseURL}/portal/management/Scores/GetScoresForDriver?driverId=${driverId}&weekspan=1`
      )
      .set("Authorization", `Bearer ${token}`)
      .retry(1, (err, res) => {
        if (res.unauthorized) {
          console.log(`401 error, retry from scores API, err is ${err}`);
          dispatch({
            type: "ACQUIRE_TOKEN_FAILURE",
            payload: null,
          });
        }
      })
      .then((response) => {
        dispatch(scoresFetched(response.body));
      })
      .catch(console.error);
  }
};

const getWeek = (date) => {
  date.setHours(0, 0, 0, 0);
  // Thursday in current week decides the year.
  date.setDate(date.getDate() + 3 - ((date.getDay() + 6) % 7));
  // January 4 is always in week 1.
  let weekOne = new Date(date.getFullYear(), 0, 4);
  // Adjust to Thursday in week 1 and count number of weeks from date to weekOne.
  return (
    1 +
    Math.round(
      ((date.getTime() - weekOne.getTime()) / 86400000 -
        3 +
        ((weekOne.getDay() + 6) % 7)) /
        7
    )
  );
};

// fetching a single driver's daily scores of the last 21 days
export const DAILY_SCORES_FETCHED = "DAILY_SCORES_FETCHED";

const dailyScoresFetched = (scores) => ({
  type: DAILY_SCORES_FETCHED,
  payload: scores,
});

// resetting the score already loaded
export const DAILY_SCORES_RESETTED = "DAILY_SCORES_RESETTED";

export const dailyScoresReset = () => ({
  type: DAILY_SCORES_RESETTED,
});


export const loadDailyScores = (driverId, date, direction, companyId) => async (dispatch, getState) => {
  const store = getState();
  var { auth } = store;
  const { driver } = store;
  const { dailyScores, coachingCompanyFeatures } = store;
  let token = auth?.accessToken;
  let dateObject = getYearMonthDateObject(date, direction);

  if (date === "initialLoad") {

    //don't send APIs if feature is not enabled for this driver
    if (coachingCompanyFeatures) {
      const findCompany = coachingCompanyFeatures.find((company) => companyId?.toLowerCase()?.includes(company?.companyName.toLowerCase()))
      if (findCompany) {
        const findFeature = findCompany?.features?.find((feature) => feature.name === "daily_scores")
        if (!findFeature) {
          return;
        }
      }
    }

    if (driver && dailyScores) {
      // Check if the request is for the same driver
      if (driver.driverId === driverId) {
        // Don't send the request if the same driver is already loaded with no stats from before
        return;
      }
    }
  } else {
    dispatch(dailyScoresReset())
  }

  // Get accessToken
  if (auth != null) {
    if (auth.accessToken == null) {
      try {
        accounts = authProvider.getAllAccounts();
        token = await getAccessToken(accounts, authProvider);
        auth = getState().auth;
      } catch (e) {
        console.log(e);
      }
    }
  }

  if (auth.accessToken) {
    request
      .get(
        `${baseURL}/portal/Management/Scores/GetDailyScoresForDriver?driverId=${driverId}&daysSpan=14&end=${dateObject.year}-${dateObject.month}-${dateObject.day}`
      )
      .set("Authorization", `Bearer ${token}`)
      .retry(1, (err, res) => {
        if (res.unauthorized) {
          console.log(`401 error, retry from scores API, err is ${err}`);
          dispatch({
            type: "ACQUIRE_TOKEN_FAILURE",
            payload: null,
          });
        }
      })
      .then((response) => {
        dispatch(dailyScoresFetched(response.body));
      })
      .catch(console.error);
  }
};

// fetching a single driver's daily scores of the last 21 days
export const MONTHLY_SCORES_FETCHED = "MONTHLY_SCORES_FETCHED";

const monthlyScoresFetched = (scores) => ({
  type: MONTHLY_SCORES_FETCHED,
  payload: scores,
});

// resetting the score already loaded
export const MONTHLY_SCORES_RESETTED = "MONTHLY_SCORES_RESETTED";

export const monthlyScoresReset = () => ({
  type: MONTHLY_SCORES_RESETTED,
});

export const loadMonthlyScores = (driverId, date, companyId) => async (dispatch, getState) => {
  const store = getState();
  var { auth } = store;
  const { driver } = store;
  const { monthlyScores, coachingCompanyFeatures } = store;
  let token = auth?.accessToken;
  let dateObject = new Date(date);

  if (date === "initialLoad") {

    //don't send APIs if feature is not enabled for this driver
    if (coachingCompanyFeatures) {
      const findCompany = coachingCompanyFeatures.find((company) => companyId?.toLowerCase()?.includes(company?.companyName.toLowerCase()))
      if (findCompany) {
        const findFeature = findCompany?.features?.find((feature) => feature.name === "daily_scores")
        if (!findFeature) {
          return;
        }
      }
    }

    dateObject=new Date()
    if (driver && monthlyScores) {
      // Check if the request is for the same driver
      if (driver.driverId === driverId) {
        // Don't send the request if the same driver is already loaded with no stats from before
        return;
      }
    }
  } else {
    dispatch(monthlyScoresReset())
  }

  // Get accessToken
  if (auth != null) {
    if (auth.accessToken == null) {
      try {
        accounts = authProvider.getAllAccounts();
        token = await getAccessToken(accounts, authProvider);
        auth = getState().auth;
      } catch (e) {
        console.log(e);
      }
    }
  }


  if (auth.accessToken) {
    request
      .get(
        `${baseURL}/portal/Management/Scores/GetMonthlyScoresPerBehavior?driverId=${driverId}&monthsSpan=5&end=${dateObject?.getFullYear()}-${dateObject?.getMonth()+1}-${dateObject?.getDate()}`
      )
      .set("Authorization", `Bearer ${token}`)
      .retry(1, (err, res) => {
        if (res.unauthorized) {
          console.log(`401 error, retry from scores API, err is ${err}`);
          dispatch({
            type: "ACQUIRE_TOKEN_FAILURE",
            payload: null,
          });
        }
      })
      .then((response) => {
        dispatch(monthlyScoresFetched(response.body));
      })
      .catch(console.error);
  }
};

const getYearMonthDateObject = (date, direction) => {
  let todaysDate;
  let nextWeeksDate;
  if (date === "initialLoad") {
    todaysDate = new Date();
    // if want to fetch data from yesterday try this
    // todaysDate.setDate(todaysDate.getDate() - 1);
  } else if (direction === "next") {
    todaysDate = new Date(date);
    todaysDate.setDate(todaysDate.getDate() + 14)
    nextWeeksDate = new Date(todaysDate);

    return {
      year: nextWeeksDate.getFullYear(),
      month: nextWeeksDate.getMonth() + 1,
      day: nextWeeksDate.getDate(),
    }
  }
  else {
    todaysDate = new Date(date);
    todaysDate.setDate(todaysDate.getDate() - 1)
  }

  return {
    year: todaysDate.getFullYear(),
    month: todaysDate.getMonth() + 1,
    day: todaysDate.getDate(),
  }
}
