import { AI_TEXT_LOADING_TOGGLE, AI_TEXT_FETCHED, AI_TEXT_CLEARED } from "../actions/coachAssist";

const initialState={
  loading:false,
  text:""
}

export default (state = initialState, action = {}) => {
  switch (action.type) {
    case AI_TEXT_FETCHED:
      return {
        ...state,
        text:action.payload
      };
    case AI_TEXT_CLEARED:
      return {
        ...state,
        text:""
      };
    case AI_TEXT_LOADING_TOGGLE:
      return {
        ...state,
        loading:!state.loading
      };
    default:
      return state;
  }
};
